import tw from 'tailwind-styled-components';

export const Line = tw.hr`
border-0
bg-lightBrown
my-16
h-px
w-11/12
m-auto
`;

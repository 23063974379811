import type { GenericLine as TGenericLine } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import { Line } from './styles';

interface IGenericLine extends ISharedTemplateProps {
  templateProps: TGenericLine;
}

export const GenericLine = ({ templateProps }: IGenericLine) => (
  <>{templateProps.enableLine && <Line />}</>
);
